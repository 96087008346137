import { useCallback, useState } from "react";

const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  return { showModal, toggleModal };
};

export default useModal;

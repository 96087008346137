import React, { useEffect, useMemo, useState } from "react";
import { Button } from "reactstrap";
import ModelsTable from "./ModelsTable";
import useFetch from "../hooks/useFetch";
import AddModelModal from "./AddorEditModelModal";
import useModal from "../hooks/useModal";

function ViewModels() {
  // Call the custom API hook to handle the API request get all models
  const { data: models, isQuerying, api: modelsAPI } = useFetch("models");

  const [refreshModels, setRefreshModels] = useState(false);

  // Handle modal visibility
  const { showModal, toggleModal } = useModal();

  const columns = useMemo(
    () => [
      {
        Header: "Model",
        accessor: "modelName",
      },
      {
        Header: "Job Number",
        accessor: "jobID",
      },
      {
        Header: "Software Type",
        accessor: "modelType",
      },
      {
        Header: "Discipline",
        accessor: "modelDiscipline.disciplineName",
      },
    ],
    []
  );

  useEffect(() => {
    const controller = new AbortController();

    // List all models
    modelsAPI.list({ signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [refreshModels]);

  return (
    <>
      <Button color="info" onClick={toggleModal} id="addModelBtn">
        Add Model
      </Button>
      <AddModelModal
        setRefreshModels={setRefreshModels}
        showModal={showModal}
        toggleModal={toggleModal}
        modelInfo={{
          modelID: null,
          modelName: null,
          jobID: null,
          discipline: null,
          softwareType: null,
          description: null,
          locations: null,
        }}
      />
      {models && <ModelsTable columns={columns} data={models} />}
      {isQuerying && <p>Loading...</p>}
    </>
  );
}

export default ViewModels;

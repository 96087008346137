import React, { createContext, useEffect, useMemo, useState } from "react";
import CommentsTable from "./CommentsTable";
import AddCommentModal from "./AddorEditCommentModal";
import useModal from "../hooks/useModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetch from "../hooks/useFetch";

export const CommentsContext = createContext({
  refreshComments: false,
  setRefreshComments: () => {},
  commentInfo: () => {},
});

function Comments() {
  // Determine whether comments data has changed or not
  const [refreshComments, setRefreshComments] = useState(false);

  // Toggle modal visibility
  const { showModal, toggleModal } = useModal();

  // Store the comment info of the selected comment to edit/delete
  const [commentInfo, setCommentInfo] = useState({});

  // Toast messages
  const notifyDeletionSuccessful = () => toast.success("Delete successful");
  const notifyDeletionUnsuccessful = () => toast.error("Something went wrong");

  // Call the custom API hook to handle API request get all comments
  const {
    data: comments,
    isQuerying: commentsIsQuerying,
    api: commentsAPI,
  } = useFetch("comments");

  useEffect(() => {
    const controller = new AbortController();
    commentsAPI.list({ signal: controller.signal });
    return () => {
      controller.abort();
    };
  }, [refreshComments]);

  async function deleteComment(commentID) {
    const commentsRes = commentsAPI.remove(commentID);

    commentsRes.then((status) => {
      if (status === 200) {
        setRefreshComments(!refreshComments);
        notifyDeletionSuccessful();
      } else {
        notifyDeletionUnsuccessful();
      }
    });
  }

  // Set the table design and accessors
  const commentColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "dateAdded",
        width: "20%",
        Cell: (props) => (
          <div> {new Date(props.value).toLocaleString("en-NZ")} </div>
        ),
      },
      {
        Header: "User",
        accessor: "userEmailAdded",
        width: "15%",
      },
      {
        Header: "Last Modified",
        accessor: "lastModified",
        Cell: (props) => (
          <div> {new Date(props.value).toLocaleString("en-NZ")} </div>
        ),
      },
      {
        Header: "Modified By",
        accessor: "userEmailLastModified",
      },
      {
        Header: "Type",
        accessor: "commentType",
        width: "15%",
      },
      {
        Header: "Comment",
        accessor: "logComment",
        width: "38%",
      },
      {
        Header: "",
        id: "edit",
        accessor: "modelID",
        width: "12%",
        Cell: (tableProps) => (
          <>
            <img
              src={require("../img/edit.png")}
              alt="Edit comment"
              id="editCommentBtn"
              onClick={() => {
                setCommentInfo({
                  commentType: tableProps.row.original.commentType,
                  logComment: tableProps.row.original.logComment,
                  commentID: tableProps.row.original.commentID,
                  dateAdded: tableProps.row.original.dateAdded,
                  userEmailAdded: tableProps.row.original.userEmailAdded,
                });
                toggleModal();
              }}
            />
            <img
              src={require("../img/delete.png")}
              alt="Delete comment"
              id="deleteCommentBtn"
              onClick={() => {
                let confirmed = window.confirm(
                  "Are you sure you want to delete the comment?"
                );
                if (confirmed) {
                  // Then delete the comment
                  let commentID = tableProps.row.original.commentID;
                  deleteComment(commentID);
                }
              }}
            />
          </>
        ),
      },
    ],
    [toggleModal]
  );

  return (
    <div className="comments">
      <CommentsContext.Provider
        value={{ refreshComments, setRefreshComments, commentInfo }}
      >
        {comments && <CommentsTable columns={commentColumns} data={comments} />}
        {commentsIsQuerying && <p>Loading...</p>}

        <AddCommentModal
          showModal={showModal}
          toggleModal={toggleModal}
          commentInfo={commentInfo}
        />
      </CommentsContext.Provider>

      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Comments;

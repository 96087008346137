import React, { useEffect, useState } from "react";
import "../style/NavMenu.css";
import "../style/Comments.css";
import Comments from "./Comments";
import AddModelModal from "./AddorEditModelModal";
import useModal from "../hooks/useModal";
import useFetch from "../hooks/useFetch";

function LogScreen() {
  const { showModal, toggleModal } = useModal();

  const [refreshModels, setRefreshModels] = useState(false);

  const {
    data: model,
    isQuerying: modelIsQuerying,
    api: modelAPI,
  } = useFetch("models");

  useEffect(() => {
    const controller = new AbortController();
    modelAPI.list({
      id: localStorage.getItem("currentModel"),
      signal: controller.signal,
    });
    return () => controller.abort();
  }, [refreshModels]);

  return (
    <div>
      {modelIsQuerying && <p></p>}
      {model && !modelIsQuerying && (
        <div className="description">
          <h2 className="title">
            <img
              src={require("../img/edit.png")}
              alt="Edit model"
              id="editModelBtn"
              onClick={toggleModal}
            />
            {model.modelName}
          </h2>
          <p className="jobDescription">
            {model.jobID} - {model.job && model.job.jobName}
          </p>
          <p className="descriptionInfo">{model.modelDescription}</p>
          <p>
            <b>Discipline: </b>
            {model.modelDiscipline && model.modelDiscipline.disciplineName}
          </p>
          <p>
            <b>Software Type: </b>
            {model.modelType}
          </p>
          <p>
            <b>Find model at: </b>
            <a href={model.locations}>{model.locations}</a>
          </p>
        </div>
      )}
      {!modelIsQuerying && <Comments />}
      {model.jobID && (
        <AddModelModal
          refreshModels={refreshModels}
          setRefreshModels={setRefreshModels}
          showModal={showModal}
          toggleModal={toggleModal}
          modelInfo={{
            modelID: localStorage.getItem("currentModel"),
            modelName: model.modelName,
            jobID: model.job.jobID,
            discipline: model.modelDiscipline.modelDisciplineID,
            modelType: model.modelType,
            description: model.modelDescription,
            locations: model.locations,
            dateAddded: model.dateAdded,
          }}
        />
      )}
    </div>
  );
}

export default LogScreen;

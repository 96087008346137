import { useState } from "react";

function useFetch(url) {
  const APIURL = process.env.REACT_APP_APIURL;

  const [data, setData] = useState([]);
  const [isQuerying, setIsQuerying] = useState(false);
  const [error, setError] = useState(null);

  const token = window.localStorage.getItem("accessToken");

  const list = async ({ id = "", signal }) => {
    try {
      setIsQuerying(true);
      const res = await fetch(`${APIURL + url}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        signal,
      });
      const resData = await res.json();
      setData(resData);
    } catch (error) {
      if (error.name !== "AbortError") {
        console.error(error);
      }
    } finally {
      setIsQuerying(false);
    }
  };

  const add = async (postData) => {
    try {
      setIsQuerying(true);
      const res = await fetch(APIURL + url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });
      if (!res.ok) {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
      setError(error);
    } finally {
      setIsQuerying(false);
    }
    return !error;
  };

  const edit = async (id, postData) => {
    try {
      setIsQuerying(true);
      const res = await fetch(`${APIURL + url}/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application.json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });

      if (!res.ok) {
        throw new Error(res.statusText);
      }
    } catch (error) {
      console.error("Error", error);
      setError(error);
    } finally {
      setIsQuerying(false);
    }
    return !error;
  };

  const remove = async (id) => {
    setIsQuerying(true);
    const st = await fetch(`${APIURL + url}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json();
        }
        return resp.status;
      })
      .catch((error) => {
        console.error("Error:", error);
        return error;
      });
    setIsQuerying(false);
    return st;
  };

  const api = {
    list,
    add,
    edit,
    remove,
  };

  return { data, isQuerying, api };
}

export default useFetch;

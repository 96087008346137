import React, { useState, useEffect } from "react";
import { useTable, useSortBy, useFilters } from "react-table";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import { Button } from "reactstrap";
import AddCommentModal from "./AddorEditCommentModal";
import useModal from "../hooks/useModal";

export default function CommentsTable({ columns, data }) {
  const { showModal, toggleModal } = useModal();

  const [modelID, setModelID] = useState("");

  // The data that's captured by useExportData includes the data that was
  // filtered out by the map if statement
  // So we need to manually filter it out again before exporting
  function transformData(data) {
    const newData = data
      .filter((item) => item[item.length - 1] == modelID) // Filter out comments for another model
      .map((item) => item.slice(0, -1)); // Remove the last element

    return newData;
  }

  // Handles the export
  function getExportFileBlob({ columns, data, fileType, fileName }) {
    let newData = transformData(data);
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data: newData });
    return new Blob([csvString], { type: "text/csv" });
  }

  // Uses the useTable hook to send the columns and data to build the table
  const {
    getTableProps, // Table props from react-table
    getTableBodyProps, // Table body props from react-table
    headerGroups, // headerGroups, if your table has grouping
    rows, // Rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter,
    exportData,
  } = useTable(
    {
      columns,
      data,
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExportData
  );

  useEffect(() => {
    setModelID(localStorage.getItem("currentModel"));
  }, []);

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? "  ▲ " : " ▼") : "";
  };

  // Handles the comment type filter
  const [filterType, setFilterType] = useState("");
  const handleFilterType = (e) => {
    const value = e.target.value || "";
    setFilter("commentType", value);
    setFilterType(value);
  };

  /*
    Render the UI for your table
     - react-table doesn't have UI, it's headless. We just need to put the react-table
     - props from the Hooks, and it will do its magic automatically
    */
  return (
    <>
      <div className="inlineParent">
        <Button
          className="inlineChild"
          id="addCommentBtn"
          color="info"
          onClick={toggleModal}
        >
          Add Comment
        </Button>
        <Button
          className="inlineChild"
          id="downloadBtn"
          color="secondary"
          onClick={() => {
            exportData("csv", false);
          }}
        >
          Download CSV
        </Button>
      </div>
      <AddCommentModal
        showModal={showModal}
        toggleModal={toggleModal}
        commentInfo={{
          commentType: null,
          logComment: null,
          commentID: null,
          dateAdded: null,
          userEmailAdded: null,
        }}
      />
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="inputGroupSelect01">
            Filter type
          </label>
        </div>
        <select
          className="custom-select"
          id="filter"
          value={filterType}
          onChange={handleFilterType}
        >
          <option key="" value="">
            All
          </option>
          <option key="Change" value="Change">
            Change
          </option>
          <option key="Anomaly" value="Anomaly">
            Anomaly
          </option>
          <option key="Update" value="Major Update">
            Major Update
          </option>
        </select>
      </div>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                if (
                  ["Last Modified", "Modified By"].indexOf(column.Header) == -1
                ) {
                  return (
                    <th
                      width={column.width}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </th>
                  );
                } else {
                  return null;
                }
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  /* eslint eqeqeq: 0 */
                  if (row.original.model.modelID == modelID) {
                    if (
                      ["Last Modified", "Modified By"].indexOf(
                        cell.column.Header
                      ) == -1
                    ) {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

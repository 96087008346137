import React, { useEffect } from "react";
import "../style/custom.css";
import ViewModels from "./ViewModels";

function Home() {
  useEffect(() => {
    localStorage.removeItem("currentModel");
  }, []);

  return (
    <div className="setMargins">
      <h1>Welcome</h1>
      <p>
        This is a centralised place to view and record changes to Beca hydraulic
        models.
      </p>
      <p>
        If the model doesn't exist yet, please add a model using the button
        below.
      </p>
      <p className="contact">
        Any questions please contact Elliot.Tuck@beca.com.
      </p>
      <ViewModels />
    </div>
  );
}

export default Home;

import React, { useContext, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import useFetch from "../hooks/useFetch";
import { useForm, Controller } from "react-hook-form";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import { CommentsContext } from "./Comments";

function AddorEditCommentModal({ showModal, toggleModal }) {
  const modalElement = document.getElementById("modal-root");
  const commentsContext = useContext(CommentsContext);

  const defaultValues = {
    commentType: commentsContext.commentInfo.commentType ?? "Change",
    logComment: commentsContext.commentInfo.logComment,
  };

  // Handles the form validation
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ defaultValues });

  const registerOptions = {
    logComment: { required: "Comment is required" },
    commentType: { required: "Comment type is required" },
  };

  useEffect(() => {
    if (commentsContext.commentInfo) {
      reset(defaultValues);
    }
  }, [commentsContext.commentInfo, reset]);

  // Set up toast messages
  const notifyEditSuccessful = () => toast.success("Edit successful");
  const notifyAddSuccessful = () => toast.success("Comment added");
  const notifyUnsuccessful = () => toast.error("Something went wrong");

  const { api: commentsAPI } = useFetch("comments");

  const handleNewComment = async (data) => {
    let dateTime = new Date().toISOString();
    let postData = {
      dateAdded: dateTime,
      lastModified: dateTime,
      userEmailAdded: window.localStorage.getItem("currentUser"),
      userEmailLastModified: window.localStorage.getItem("currentUser"),
      logComment: data.logComment,
      commentType: data.commentType,
      modelID: localStorage.getItem("currentModel"),
    };

    const success = await commentsAPI.add(postData);
    if (!success) {
      notifyUnsuccessful();
      return;
    }

    toggleModal();
    notifyAddSuccessful();
    commentsContext.setRefreshComments(!commentsContext.refreshComments);
    reset();
  };

  const handleEditComment = async (data) => {
    let dateTime = new Date().toISOString();
    let postData = {
      commentID: commentsContext.commentInfo.commentID,
      dateAdded: commentsContext.commentInfo.dateAdded,
      lastModified: dateTime,
      userEmailAdded: commentsContext.commentInfo.userEmailAdded,
      useEmailLastModified: window.localStorage.getItem("currentUser"),
      logComment: data.logComment,
      commentType: data.commentType,
      modelID: localStorage.getItem("currentModel"),
    };

    const success = await commentsAPI.edit(
      commentsContext.commentInfo.commentID,
      postData
    );
    if (!success) {
      notifyUnsuccessful();
      return;
    }

    toggleModal();
    notifyEditSuccessful();
    commentsContext.setRefreshComments(!commentsContext.refreshComments);
    reset();
  };

  return createPortal(
    <div className="comments">
      <div id="commentModalID">
        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className="modal-dialog-centered"
        >
          <Form
            onSubmit={
              commentsContext.commentInfo.commentType != null
                ? handleSubmit(handleEditComment)
                : handleSubmit(handleNewComment)
            }
          >
            <ModalHeader>
              {commentsContext.commentInfo.commentType != null
                ? "Edit Comment"
                : "Add Comment"}
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="inputCommentType">Type</Label>
                <Controller
                  control={control}
                  name="commentType"
                  rules={registerOptions.commentType}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        type="select"
                        className="form-control"
                        id="inputCommentType"
                      >
                        <option key="change" value="Change">
                          Change
                        </option>
                        <option key="anomaly" value="Anomaly">
                          Anomaly
                        </option>
                        <option key="update" value="Major Update">
                          Major Update
                        </option>
                      </Input>
                    );
                  }}
                />
                <small className="text-danger">
                  {errors.commentType && errors.commentType.message}
                </small>
              </FormGroup>
              <FormGroup>
                <Label for="inputComment">Comment</Label>
                <Controller
                  control={control}
                  name="logComment"
                  rules={registerOptions.logComment}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        type="textarea"
                        className="form-control"
                        id="inputComment"
                        placeholder="Enter your comment"
                        rows="4"
                      ></Input>
                    );
                  }}
                />

                <small className="text-danger">
                  {errors.logComment && errors.logComment.message}
                </small>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>,

    modalElement
  );
}

export default AddorEditCommentModal;

import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { createPortal } from "react-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetch from "../hooks/useFetch";

function AddorEditModelModal({
  showModal,
  toggleModal,
  modelInfo,
  setRefreshModels,
}) {
  const modalElement = document.getElementById("modal-root");

  const defaultValues = {
    modelName: modelInfo.modelName,
    existingJobID: modelInfo.jobID,
    digitalWorkspaceLink: modelInfo.digitalWorkspaceLink,
    modelDisciplineID: modelInfo.discipline,
    modelType: modelInfo.modelType,
    modelDescription: modelInfo.modelDescription,
    locations: modelInfo.locations,
  };

  const notifyEditSuccessful = () => toast.success("Edit successful");
  const notifyAddSuccessful = () => toast.success("Model added");

  const notifyUnsuccessful = () => toast.error("Something went wrong");
  const notifyJobAlreadyExists = () => toast.error("Job already exists");

  // Handles the visibility of adding a new job
  const [newJobVisibility, setNewJobVisibility] = useState(false);
  const toggleJobVisibility = () => {
    setNewJobVisibility(!newJobVisibility);
  };

  // Handles API for adding new models
  const { api: modelsAPI } = useFetch("models");

  // Handles API for jobs
  const { data: jobs, api: jobsAPI } = useFetch("jobs");

  // Handles API for disciplines
  const { data: disciplines, api: disciplinesAPI } =
    useFetch("modeldisciplines");

  // Handles the form validation
  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    control,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (modelInfo) {
      reset(defaultValues);
    }
  }, [reset, modelInfo]);

  useEffect(() => {
    const controller = new AbortController();

    jobsAPI.list({ signal: controller.signal });
    disciplinesAPI.list({ signal: controller.signal });

    if (newJobVisibility) {
      unregister(["existingJobID"]);
      register("newJobID");
      register("jobName");
      register("digitalWorkspaceLink");
    } else {
      unregister(["newJobID", "jobName", "digitalWorkspaceLink"]);
      register("existingJobID");
    }

    return () => {
      controller.abort();
    };
  }, [newJobVisibility, register, unregister]);

  const handleNewModel = async (data) => {
    let dateTime = new Date().toISOString();
    let postData = {
      ...data,
      dateAdded: dateTime,
      lastModified: dateTime,
    };

    // Add a new job if required
    // Otherwise go straight to adding a model
    if (newJobVisibility) {
      // Setup job data
      let jobData = {
        jobID: data.newJobID,
        jobName: data.jobName,
        digitalWorkspaceLink: data.digitalWorkspaceLink,
      };

      // Add the new job and get the return status
      const jobSuccess = await jobsAPI.add(jobData);

      if (jobSuccess) {
        const modelSuccess = modelsAPI.add({
          ...postData,
          jobID: data.newJobID,
        });

        if (modelSuccess) {
          // If adding the model was successful, then refresh the data
          setRefreshModels((refreshModels) => !refreshModels);
          notifyAddSuccessful();
          toggleModal();
          reset();
        } else {
          // If adding the model was unsuccessful, notify the user
          notifyUnsuccessful();
        }
      } else {
        // If adding the job was unsuccessful, notify the user
        notifyJobAlreadyExists();
      }
    } else {
      const modelSuccess = modelsAPI.add({
        ...postData,
        jobID: data.existingJobID,
      });

      if (modelSuccess) {
        setRefreshModels((refreshModels) => !refreshModels);
        notifyAddSuccessful();
        toggleModal();
        reset();
      } else {
        notifyUnsuccessful();
      }
    }
  };

  const handleEditModel = (data) => {
    let currentModel = localStorage.getItem("currentModel");
    let postData = {
      ...data,
      modelID: currentModel,
      dateAdded: modelInfo.dateAdded,
      lastModified: new Date().toISOString(),
    };

    // Add a new job if required
    // Otherwise go straight to updating the model
    if (newJobVisibility) {
      // Setup job data
      let jobData = {
        jobID: data.newJobID,
        jobName: data.jobName,
        digitalWorkspaceLink: data.digitalWorkspaceLink,
      };

      // Add the new job and get the return status
      const jobSuccess = jobsAPI.add(jobData);

      if (jobSuccess) {
        const modelSuccess = modelsAPI.edit(currentModel, {
          ...postData,
          jobID: data.newJobID,
        });

        if (modelSuccess) {
          toggleModal();
          notifyEditSuccessful();
          setRefreshModels((refreshModels) => !refreshModels);
          reset();
        } else {
          notifyUnsuccessful();
        }
      } else {
        // If adding the job was unsuccessful, notify the user
        notifyJobAlreadyExists();
      }
    } else {
      const modelSuccess = modelsAPI.edit(currentModel, {
        ...postData,
        jobID: data.existingJobID,
      });

      if (modelSuccess) {
        setRefreshModels((refreshModels) => !refreshModels);
        notifyEditSuccessful();
        toggleModal();
        reset();
      } else {
        notifyUnsuccessful();
      }
    }
  };

  const registerOptions = {
    modelName: { required: "Name is required" },
    existingJobID: { required: "Job number is required" },
    newJobID: {
      required: "Job number is required",
      maxLength: {
        value: 7,
        message: "Job number must be 7 digits long",
      },
      minLength: {
        value: 7,
        message: "Job number must be 7 digits long",
      },
    },
    jobName: { required: "Job name is required" },
    digitalWorkspaceLink: { validate: false },
    modelDisciplineID: { required: "Model discipline is required" },
    modelType: { required: "Software type is required" },
    modelDescription: { validate: false },
    locations: { validate: false },
  };

  return createPortal(
    <>
      <div id="modal-div">
        <Modal
          isOpen={showModal}
          toggle={toggleModal}
          className="modal-dialog-centered"
        >
          <Form
            onSubmit={
              modelInfo.modelID != null
                ? handleSubmit(handleEditModel)
                : handleSubmit(handleNewModel)
            }
          >
            <ModalHeader>
              {modelInfo.modelID != null ? "Edit Model" : "Add Model"}
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="inputModelName">Name</Label>
                <Controller
                  control={control}
                  name={"modelName"}
                  rules={registerOptions.modelName}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        value={field.value || ""}
                        type="text"
                        className="form-control"
                        id="inputModelName"
                        placeholder="Enter model name"
                      ></Input>
                    );
                  }}
                />
                <small className="text-danger">
                  {errors.modelName && errors.modelName.message}
                </small>
              </FormGroup>
              {!newJobVisibility && (
                <FormGroup>
                  <label htmlFor="inputExistingJobID">Job</label>
                  <br></br>
                  <div className="form-width">
                    <Controller
                      control={control}
                      name="existingJobID"
                      rules={registerOptions.existingJobID}
                      render={({ field }) => {
                        return (
                          <Input
                            {...field}
                            value={field.value || ""}
                            type="select"
                            id="inputExistingJobID"
                          >
                            <option key="placeholder"></option>
                            {jobs &&
                              jobs.map((item) => (
                                <option value={item.jobID} key={item.jobID}>
                                  {item.jobID} - {item.jobName}
                                </option>
                              ))}
                          </Input>
                        );
                      }}
                    />
                    <small className="text-danger">
                      {errors.existingJobID && errors.existingJobID.message}
                    </small>
                  </div>
                </FormGroup>
              )}

              {newJobVisibility && (
                <FormGroup>
                  <Label for="inputNewJobID">Job Number</Label>
                  <Input
                    type="number"
                    name="newJobID"
                    className="form-control"
                    id="inputNewJobID"
                    placeholder="Enter job number"
                    {...register("newJobID", registerOptions.newJobID)}
                  ></Input>
                  <small className="text-danger">
                    {errors.newJobID && errors.newJobID.message}
                  </small>
                </FormGroup>
              )}
              {newJobVisibility && (
                <FormGroup>
                  <Label for="inputJobName">Job Name</Label>
                  <Input
                    type="text"
                    name="jobName"
                    className="form-control"
                    id="inputJobName"
                    placeholder="Enter job name"
                    {...register("jobName", registerOptions.jobName)}
                  ></Input>
                  <small className="text-danger">
                    {errors.jobName && errors.jobName.message}
                  </small>
                </FormGroup>
              )}
              {newJobVisibility && (
                <FormGroup>
                  <Label for="inputWorkspaceLink">Digital Workspace Link</Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="inputWorkspaceLink"
                    name="digitalWorkspaceLink"
                    placeholder="Enter digital workspace link"
                    {...register(
                      "digitalWorkspaceLink",
                      registerOptions.digitalWorkspaceLink
                    )}
                  ></Input>
                </FormGroup>
              )}
              <button
                type="button"
                className="btn btn-outline-info btn-sm addNewJobBtn"
                onClick={toggleJobVisibility}
              >
                {newJobVisibility
                  ? "Or select from existing jobs"
                  : "Or add new job"}
              </button>
              <FormGroup>
                <label htmlFor="inputDiscipline">Discipline</label>
                <div className="form-width">
                  <Controller
                    control={control}
                    name="modelDisciplineID"
                    rules={registerOptions.modelDisciplineID}
                    render={({ field }) => {
                      return (
                        <Input
                          {...field}
                          value={field.value || ""}
                          type="select"
                          id="inputDiscipline"
                          name="modelDisciplineID"
                        >
                          <option></option>
                          {disciplines &&
                            disciplines.map((item) => (
                              <option
                                value={item.modelDisciplineID}
                                key={item.modelDisciplineID}
                              >
                                {item.disciplineName}
                              </option>
                            ))}
                        </Input>
                      );
                    }}
                  />
                  <small className="text-danger">
                    {errors.modelDisciplineID &&
                      errors.modelDisciplineID.message}
                  </small>
                </div>
              </FormGroup>
              <FormGroup>
                <label htmlFor="inputModelType">Software Type</label>
                <div className="form-width">
                  <datalist id="inputModelType" className="">
                    <option key="mike">Mike</option>
                    <option key="icm">ICM</option>
                    <option key="hec-ras">HEC-RAS</option>
                    <option key="tuflow">TUFLOW</option>
                    <option key="ws">WS</option>
                    <option key="hec-hms">HEC-HMS</option>
                  </datalist>
                  <input
                    autoComplete="on"
                    list="inputModelType"
                    className="form-control mr-sm-2 dropdown-toggle"
                    placeholder="Search types"
                    aria-label="Search types"
                    id="searchModelTypes"
                    type="search"
                    name="modelType"
                    defaultValue={modelInfo.modelType}
                    {...register("modelType", registerOptions.modelType)}
                  />
                  <small className="text-danger">
                    {errors.modelType && errors.modelType.message}
                  </small>
                </div>
              </FormGroup>
              <FormGroup>
                <label htmlFor="inputDescription">Description</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputDescription"
                  placeholder="Enter model description"
                  rows="4"
                  name="modelDescription"
                  defaultValue={modelInfo.description}
                  {...register(
                    "modelDescription",
                    registerOptions.modelDescription
                  )}
                ></textarea>
              </FormGroup>
              <FormGroup>
                <label htmlFor="inputLocations">Locations</label>
                <textarea
                  type="text"
                  className="form-control"
                  id="inputLocations"
                  placeholder="Where can the model be found?"
                  rows="2"
                  name="locations"
                  defaultValue={modelInfo.locations}
                  {...register("locations", registerOptions.locations)}
                ></textarea>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit">
                Save
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>,

    modalElement
  );
}

export default AddorEditModelModal;

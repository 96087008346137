// authProvider.js
import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Msal Configurations
const config = {
    auth: {
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};

// Authentication Parameters
const authenticationParameters = {
    scopes: ["user.read"],
};

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(
    config,
    authenticationParameters,
    options
);

import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './authProvider';

import 'bootstrap/dist/css/bootstrap.css';
import App from './App';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin={true}>
      <App />
  </AzureAD>,
  rootElement
);
import React from "react";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { Navbar, Button } from "reactstrap";

import "./style/custom.css";
import LogScreen from "./components/LogScreen";
import Home from "./components/Home";

import { AzureAD, AuthenticationState } from "react-aad-msal";

// Import the provider created in a different file
import { authProvider } from "./authProvider";

export default function App() {
  return (
    <div className="App">
      <AzureAD provider={authProvider} forceLogin={true}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              window.localStorage.setItem(
                "accessToken",
                accountInfo.jwtIdToken
              );
              window.localStorage.setItem(
                "currentUser",
                accountInfo.account.name
              );
              return (
                <BrowserRouter>
                  <header>
                    <Navbar
                      className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
                      light
                    >
                      <div className="container-fluid" id="setWidth">
                        <div className="form-inline">
                          <Link to="/">
                            <img
                              className="logo"
                              src={require("./img/Beca Logo Black.png")}
                              alt="Beca logo"
                            />
                          </Link>
                          <Link to="/" className="homeBtn">
                            Model Log Tool
                          </Link>
                        </div>
                        <ul className="navbar-nav flex-grow">
                          <div className="form-inline">
                            <span className="welcome">
                              Welcome, {accountInfo.account.name}!
                            </span>
                            <Button
                              color="secondary"
                              onClick={() => {
                                window.localStorage.removeItem("accountInfo");
                                logout();
                              }}
                            >
                              Logout
                            </Button>
                          </div>
                        </ul>
                      </div>
                    </Navbar>
                  </header>

                  <Switch>
                    <Route path="/model">
                      <LogScreen />
                    </Route>
                    <Route path="/" exact>
                      <Home />
                    </Route>
                  </Switch>
                </BrowserRouter>
              );
            case AuthenticationState.Unauthenticated:
              return (
                <div>
                  {window.localStorage.clear()}
                  {error && (
                    <p>
                      <span>
                        An error occured during authentication, please try
                        again!
                      </span>
                    </p>
                  )}
                  <p>
                    <span>Hey stranger, you look new!</span>
                    <button onClick={login}>Login</button>
                  </p>
                </div>
              );
            case AuthenticationState.InProgress:
              return <p>Authenticating...</p>;
            default:
          }
        }}
      </AzureAD>
    </div>
  );
}

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFilters, useTable } from "react-table";

export default function ModelsTable({ columns, data }) {
  // Table component logic and UI go here

  // Handles the page changes
  const history = useHistory();

  // Uses the useTable hook to send the columns and data to build the table
  const {
    getTableProps, // Table props from react-table
    getTableBodyProps, // Table body props from react-table
    headerGroups, // headerGroups, if your table has grouping
    rows, // Rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    setFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters // Adding the useFilters hook to the table
  );

  // Handles the model name search
  const [filterModelName, setFilterModelName] = useState("");
  const handleFilterModelName = (e) => {
    const value = e.target.value || "";
    setFilter("modelName", value); // Update the name filter.
    setFilterModelName(value);
  };

  // Handles the job ID search
  const [filterJobID, setFilterJobID] = useState("");
  const handleFilterJobID = (e) => {
    const value = e.target.value || "";
    setFilter("jobID", value); // Update the name filter.
    setFilterJobID(value);
  };

  // Handles the model type search
  const [filterType, setFilterType] = useState("");
  const handleFilterType = (e) => {
    const value = e.target.value || "";
    setFilter("modelType", value); // Update the name filter.
    setFilterType(value);
  };

  // Handles the discipline search
  const [filterDiscipline, setFilterDiscipline] = useState("");
  const handleFilterDiscipline = (e) => {
    const value = e.target.value || "";
    setFilter("modelDiscipline.disciplineName", value); // Update the name filter.
    setFilterDiscipline(value);
  };

  /*
    Render the UI for your table
     - react-table doesn't have UI, it's headless. We just need to put the react-table
     - props from the Hooks, and it will do its magic automatically
    */
  return (
    <table className="table table-hover" id="modelsTable" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
        <tr className="table-sm">
          <td>
            <input
              className="form-control mr-sm-2"
              type="search"
              value={filterModelName}
              placeholder="Search model name"
              onChange={handleFilterModelName}
            />
          </td>
          <td>
            <input
              className="form-control mr-sm-2"
              type="search"
              value={filterJobID}
              placeholder="Search job number"
              onChange={handleFilterJobID}
            />
          </td>
          <td>
            <input
              className="form-control mr-sm-2"
              type="search"
              value={filterType}
              placeholder="Search software type"
              onChange={handleFilterType}
            />
          </td>
          <td>
            <input
              className="form-control mr-sm-2"
              type="search"
              value={filterDiscipline}
              placeholder="Search discipline"
              onChange={handleFilterDiscipline}
            />
          </td>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              className="modelRow"
              onClick={() => {
                localStorage.setItem("currentModel", row.original.modelID);
                history.push("/model");
                // history.push(`/model/${modelName}`);
              }}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
